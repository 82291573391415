const stopwords = require('stopwords-es');
const levenshtein = require('levenshtein-edit-distance')

function clearQuotes(string){
  return string.replace(/['"]+/g, '')
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function highlightText(text, highlight_words) {
  if (!text) {return ""}
  highlight_words.forEach((word) => {
    var regEx = new RegExp(`[ .–-]${word}[ .–-]`, "ig");
    text = text.replace(regEx, "<mark>$&</mark>")
  });
  return text
}

function replaceReferences(text, references) {
  if ((typeof references == "undefined") || isEmpty(references)) {return text}
  let tomo_pages_aux = text.trim().split(/,|;|y/);
  let tomo = null;
  let tomo_text = text;
  tomo_pages_aux.forEach((tomo_page, i) => {
    let pagina = null;
    if (tomo_page.includes(":")) {
      let aux = tomo_page.split(":");
      tomo = aux[0].trim();
      pagina = aux[1].trim();
    } else {
      pagina = tomo_page.trim();
    }
    if (!(tomo in references) || !(pagina in references[tomo])) {
      return
    }
    if (references[tomo][pagina] === "") {
      return
    }
    if (references[tomo][pagina]['id']) {
      let dictamen_id = references[tomo][pagina]['id'];
      let page_text = "<referencias id=\"" + dictamen_id + "\">" + pagina + "</referencias>";
      tomo_text = tomo_text.replace(pagina, page_text);
    }
  });
  return tomo_text
}

function buildAsociatedDoctLinks(text, doctrinas_info, fallos_info) {
  if (isEmpty(doctrinas_info)) {return text}
  // Aca hacer cosas
  let regexp = /([\D]*?(?:N|n)?(?:[°º. ]?)*|\()([0-9\.]+:[0-9\.]+([\s]*bis){0,1}([\s]*[,y;][\s]*([0-9\.]+:)*[0-9\.]+([\s]*bis){0,1})*)/ig;
  let regexp_parentesis = /(\($)/ig;
  let regex_tipo = /(\w+)\W*$/ig;
  //let regexp = /(?:(En igual sentido|\(v\.) dictámenes )(\d*[y,;:.)\s])*/ig;
  let regexp_number = /(\d+:\d+)[\s),;.](?:y?\s\d+[,;.)])*/ig
  let doctrinas_asociadas_list = [...text.matchAll(regexp)];
  doctrinas_asociadas_list.forEach((doct_text, i) => {
    let texto_busqueda = doct_text[1];
    texto_busqueda = texto_busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let text_result = [...texto_busqueda.matchAll(regexp_parentesis)];
    if (text_result.length > 0) {
      let text_referenced = replaceReferences(doct_text[2], doctrinas_info)
      text = text.replace(doct_text[2], text_referenced);
    } else {
      text_result = [...texto_busqueda.matchAll(regex_tipo)];
      if (text_result.length > 0) {
        let encontrado = text_result[0][1];
        encontrado = encontrado.toLowerCase();
        let palabras_dictamen = ["dictamen", "dictamenes", "dict", "sentido", "conf"];
        for (var j = 0; j < palabras_dictamen.length; j++) {
          let d = levenshtein(encontrado, palabras_dictamen[j]);
          if (d <= 2) {
            let text_referenced = replaceReferences(doct_text[2], doctrinas_info);
            text = text.replace(doct_text[2], text_referenced);
            break;
          }
        }
        let palabras_fallo = ["fallo", "fallos", "csjn"];
        for (var j = 0; j < palabras_fallo.length; j++) {
          let d = levenshtein(encontrado, palabras_fallo[j]);
          if (d <= 2) {
            let text_referenced = replaceReferences(doct_text[2], fallos_info);
            text = text.replace(doct_text[2], text_referenced);
            break;
          }
        }
      }
    }
  });
  return text;
}

function createTomosText(tomo_pages, doctrinas_info) {
  let tomo_pages_aux = tomo_pages.trim().split(/:|,|;|y/)
  let tomo = tomo_pages_aux[0]
  let pages = tomo_pages_aux.slice(1)
  let tomo_text = `${tomo}:`
  pages.forEach((page, i) => {
    if (page.length === 0) {return}
    page = page.match(/\d+/)[0]
    if (!(tomo in doctrinas_info) || !(page in doctrinas_info[tomo])) {
      tomo_text = `${tomo_text}${page}, `
      return
    }
  });
  return tomo_text
}

function buildLawLink(text, laws_urls) {
  if (isEmpty(laws_urls)) {return text}
  let regexp = "(?:Ley (?:N|n)?(?:[°º. ]?)*)(?:[0-9.]+)";
  let regexp_number = "(?:[0-9]+.?[0-9]*)";
  let laws_list = [...text.matchAll(regexp)];
  laws_list.forEach((law, i) => {
    let law_number = [...law[0].matchAll(regexp_number)][0][0].replace('.','');
    let law_url = laws_urls[law_number]
    let link_html = "<a href='" + law_url + "' target='_blank'>" + law[0] + "</a>";
    var searchLaw = new RegExp(`[^>]${law[0]}[^<]`, "ig");
    text = text.replace(searchLaw,` ${link_html} `)
  });
  return text
}

function buildDecretosLink(text, decretos_urls) {
  if ((typeof decretos_urls == "undefined") || isEmpty(decretos_urls)) {return text}
  let regexp = "(?:Decreto (?:N|n)?(?:[°º. ]?)*)(?:[0-9.]+[\/]{0,1}[0-9]+)";
  let regexp_number = "(?:[0-9.]+[\/]{0,1}[0-9]+)";
  let decretos_list = [...text.matchAll(regexp)];
  decretos_list.forEach((decreto, i) => {
    let decreto_number = [...decreto[0].matchAll(regexp_number)][0][0].replace('.','');
    let decreto_url = decretos_urls[decreto_number]
    if (decreto_url) {
      let link_html = "<a href='" + decreto_url + "' target='_blank'>" + decreto[0] + "</a>";
      var searchDecreto = new RegExp(`[^>]${decreto[0]}[^<]`, "ig");
      text = text.replace(searchDecreto,` ${link_html} `)
    }
  });
  return text
}

export function processResultText(doctrina, highlight_words, leyes, doctrinas, decretos, fallos) {
  let text = doctrina.replace(/\s+/g,' ')
  var processedText = buildAsociatedDoctLinks(text, doctrinas, fallos)
  processedText = buildLawLink(processedText, leyes);
  processedText = buildDecretosLink(processedText, decretos);
  return highlightText(processedText, highlight_words);
}

export function wordsToBeHighlighted(searchTerm) {
  if (searchTerm.includes("BusquedaAvanzada")) return [];
  if (!searchTerm) return [];
  var splited_words = searchTerm.split(" ")
                                .map(clearQuotes)
                                .filter(x => !stopwords.includes(x) )
  var quotes_match = (searchTerm.match(/"([^"]*)"/ig) || []).map(clearQuotes)
  return [searchTerm].concat(quotes_match)
                     .concat(splited_words)
}

export function removeDuplicates(list) {
  var uniqueItems = [];
  list.forEach((item, i) => {
    if (!uniqueItems.includes(item)) uniqueItems.push(item)
  });
  return uniqueItems
}
