import React, { Component } from 'react';
import { ReactiveBase, ReactiveList, MultiList, SingleRange, ResultCard } from '@appbaseio/reactivesearch';
import resultView from './views/ResultView';
import { wordsToBeHighlighted, processResultText, removeDuplicates } from "./helpers/processResultText";
import officeBuildingOutline from '@iconify/icons-mdi/office-building-outline';
import accountTieVoiceOutline from '@iconify/icons-mdi/account-tie-voice-outline';
import { Icon } from '@iconify/react';
import { Typography, LinearProgress, Button } from '@material-ui/core';
// import { PtnButton } from 'ptn-components';

const GREEN_COLOR = "#2ED47A"
const YELLOW_COLOR = "#FFB946"

function processItem(item, index) {
  var doctrina = item._source.attachments[1].attachment.content;
  var leyes = item._source.leyes;
  var doctrinas = item._source.doctrinas_asociadas;
  var decretos = item._source.decretos;
  var fallos = item._source.fallos_asociados;
  item._source.color = (index % 2 === 0) ? YELLOW_COLOR : GREEN_COLOR
  item._source.doctrina = processResultText(doctrina, [], leyes, doctrinas, decretos, fallos);
  return item;
}

function renderStats(stats) {
  return <div style={stastsStyle} >Hay {stats.numberOfResults} Dictámenes nuevos en los últimos 4 meses</div>
}

function renderTitle(label) {
  const icon = (label === "Organismo") ? officeBuildingOutline : accountTieVoiceOutline;
  return <legend><Typography variant="h6" style={{display: "flex", alignItems: "center", textTransform: "none"}}> <Icon icon={officeBuildingOutline} style={{color: "#C2CFE0", fontSize: "19.5px", marginRight: "5px"}}/> <span id={`${label}-label`}>{label}</span> </Typography> </legend>
}

const stastsStyle = {
  padding: '15px 0px 0px 25px',
  fontSize: '12pt',
  fontStyle: 'normal',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: '500',
  lineHeight: '1.6 !important',
}

const filterStyle = {
  padding: '5px', 
  backgroundColor: '#FFF',
  overflow: 'scroll',
  margin: '15px',
}

class App extends Component {
  render() {
    return (
        <ReactiveBase
          className="App"
          app="search_news"
          url={window._env_.REACT_APP_API_URL}
          transformRequest = {(request) => {
            var body = request['body']
            var query = JSON.parse(body.substring(body.indexOf("\n") + 1).trim())

            request['body'] = JSON.stringify(query);
            request['url'] = `${window._env_.REACT_APP_API_URL}/search_news`
            return request
          }}
          transformResponse = {async (elasticsearchResponse, componentId) => {
            const hits = elasticsearchResponse.hits.hits.map((item, index) => {processItem(item, index)})
            return elasticsearchResponse;
          }}
        >
        <div className='body-container' style={{display: 'flex', flexFlow:'row', padding:'15px'}}>
          <div style={{display: 'flex', flexFlow:'column', padding:'15px', backgroundColor:'#FFF', maxWidth: '280px'}}>
          <Button variant='contained' color="secondary" style={{marginTop: "15px", marginLeft: "10px", padding:"10px"}} onClick = {(e) => { e.preventDefault(); window.location.href=`${window._env_.REACT_APP_BUSCADOR_URL}`;}} >
            Ir al Buscador de Dictamenes 
          </Button>         
            <hr style={{width: '100%'}} />
            <div style={filterStyle} ><MultiList placeholder="Filtrar por Organismo" className='filter' innerClass={{title:'filter-title', input:'filter-search', ul:'filter-text'}} componentId="OrganismoSensor" dataField="organismo.keyword" title={renderTitle('Organismo')} /></div>
            <div style={filterStyle} ><MultiList placeholder="Filtrar por Voces" className='filter' innerClass={{title:'filter-title', input:'filter-search', ul:'filter-text'}} componentId="VocesSensor" dataField="voces.keyword" title={renderTitle('Voces')} /></div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent:'center'  }}>
            <ReactiveList
              style={{backgroundColor:'#F5F6F8'}}
              componentId="SearchResult"
              dataField = "voces"
              react={{
                  and: ['OrganismoSensor', 'VocesSensor'],
              }}
              renderItem={resultView}
              loader = {<div className="loader-container">  <div className="loader"> <LinearProgress color="secondary"/> </div> </div>}
              renderResultStats= {renderStats}
          />

          </div>
        </div>          
        </ReactiveBase>
    );
  }
}

export default App;
