import React from "react";
import ReadMore from '../components/readMore';
import { Divider, Button, Typography, Paper, Grid } from '@material-ui/core';
import { Icon } from '@iconify/react';
import fileDownload from '@iconify/icons-mdi/file-download';
import RecaptchaLink from '../components/recaptcha';


export default ( result ) => (

  <div className="result-root" key={result._id}>
    <div style = {{padding:'25px'}}>
    <Paper elevation={3} className="result-paper" style={{borderLeft: 'solid 5px', borderColor: result.color, padding: '25px', display:'flex', flexFlow:'row', justifyContent: 'space-between' }}>
      <div style={{display: 'flex', flexFlow: 'column'}}>
        <Typography variant="h1" gutterBottom>
          {result.voces.join(". ")}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {result.organismo}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Fecha: {result.fecha}
        </Typography>
        <Divider style={{margin: "15px -2px", backgroundColor:"#C2CFE0"}}/>
        {(result.doctrina)!=='' ? 
          <Typography variant="subtitle1">
            Texto de la doctrina:
          </Typography> : ''}
          
        <span className="doctrina_text">
          <ReadMore text={result.doctrina} readMoreText="Leer más" readLessText="Leer menos"/>
        </span>
        </div>
        <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center', padding:'5px', margin:'5px' }}>
          <div style={{padding:'inherit', margin:'inherit'}}>
            <Icon icon={fileDownload} style={{color: "#C2CFE0", width: 32, height:32}}/>
          </div>
          <div style={{padding:'inherit', margin:'inherit'}}>
          {(result.doctrina)!=='' ? 
            <RecaptchaLink type="doctrina" id={result._id} label="Ver Doctrina" />: ''}
          </div>
          <div style={{padding:'inherit', margin:'inherit'}}>
            <RecaptchaLink type="dictamen" id={result._id} label="Ver Dictámen" />
          </div>
        </div>
      </Paper>
    </div>
  </div>
);