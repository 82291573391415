import React from 'react';
import { Container } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import worldIcon from '@iconify/icons-tabler/world';
import mapMarkerOutline from '@iconify/icons-mdi/map-marker-outline';
import outlineMail from '@iconify/icons-ic/outline-mail';

const logo = require('../assets/iso_ptn.png').default;
const logoFooter = require('../assets/iso_footer.png').default;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#707683",
    },
    secondary: {
      main: "#097CC1",
    },
  },
  overrides: {
    MuiFab: {
      root:{
        position: 'fixed',
        bottom: 20,
        right: 60,
        zIndex:2,
        backgroundColor:"none",
        // border: "4px solid #097CC1",
        width: 80,
        height: 80,
        textTransform: "none",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "none",
          // boxShadow: "0px 0px 5px 0px",
        }
      }
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: "#C2CFE0"
      }
    },
    MuiButton: {
      outlinedPrimary: {
        color: "#097CC1",
        border: "1px solid rgb(27, 160, 241)",
        textTransform: "",
        fontSize: "11pt",
        "&:hover": {
          backgroundColor: "#FFFFFF",
          border: "1px solid rgb(27, 160, 241)",
          opacity: 0.7,
          boxShadow: "0px 0px 1px 0px"
        }
        // boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)"
      },
      sizeSmall: {
        width: 127
      },
      root: {
        textTransform: "",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
      },
      outlinedSizeSmall: {
        fontSize: "11pt"
      }
    },
    MuiTypography: {
      // Titulo busqueda dictamenes
      overline: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "22pt",
        color: "#FFFFFF",
        textTransform: "none"
      },
      // Texto de Voces
      h1: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "13pt",
        color: "#323C47"
      },
      // Texto organismo
      subtitle1: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "12pt",
        color: "#323C47"
      },
      // Texto datos de doctrina
      subtitle2: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "12pt",
        color: "#707683",
        lineHeight: 1.1
      },
      // Texto doctrina
      body1: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "11pt",
        color: "#323C47"
      },
      body2: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "10pt",
        color: "#097CC1"
      },
      caption: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "10pt",
        color: "#717171"
      },
      // Titulo filtros
      h6: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "12pt",
        color: "#707683"
      },
      // Texto ayuda home
      h5: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "11pt",
        color: "#097CC1"
      },
      // Opciones checkbox
      h4: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "11pt",
        color: "#707683"
      },
      // Texto slider
      h3: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "9pt",
        color: "#707683"
      },
      // Texto ayuda
      h2: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "11pt",
        color: "#0563c1"
      }
    }
  }
});

export default function Layout(props) {
    return (
      <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <Container >
          <header>
            <a title="Procuración del Tesoro" href="https://www.argentina.gob.ar/procuraciondeltesoro">
              <img className="ptn-logo" src={logo} alt="Procuración del Tesoro - Header" />
            </a>
            <Typography variant="overline">
            <a style={{textDecoration: "none"}} href="./">Novedades de Dictámenes</a>
            </Typography>
            <div style={{visibility:"hidden"}}>
            <img className="ptn-logo" src={logo} alt="Procuración del Tesoro" />
            </div>
          </header>
          <div className="header-subtitle">

          </div>
          {props.children}
          <footer>
            <div className="footer">
              <div>
                <a title="Procuración del Tesoro" href="https://www.argentina.gob.ar/procuraciondeltesoro">
                  <img src={logoFooter} alt="Procuración del Tesoro - Footer" />
                </a>
              </div>
              <div style={{display: "flex", flexFlow: "column nowrap"}}>

                <div style={{display: "flex", flexFlow: "row nowrap"}}>
                  <Icon icon={mapMarkerOutline} style={{color:"#FFFFFF", width: 20, height: "auto", marginLeft: 50, marginRight: 20}}/>
                  <p>Posadas 1641 - CP: 1112 - Buenos Aires - Argentina <br/>
                  [+54] +11 4804-3482 / 5385 / 7598 </p>
                </div>
                <div style={{display: "flex", flexFlow: "row nowrap"}}>
                  <Icon icon={worldIcon} style={{color:"#FFFFFF", width: 20, height: "auto", marginLeft: 50, marginRight: 20}}/>
                  <a href="https://www.argentina.gob.ar/procuraciondeltesoro" target="_blank" rel="noopener noreferrer">argentina.gob.ar/procuraciondeltesoro</a>
                </div>
                <div style={{display: "flex", flexFlow: "row nowrap", paddingBottom:10, paddingTop:15}}>
                  <Icon icon={outlineMail} style={{color:"#FFFFFF", width: 20, height: "auto", marginLeft: 50, marginRight: 20}}/>
                  <a href="mailto:biblioteca@ptn.gob.ar">biblioteca@ptn.gob.ar</a>
                </div>
              </div>
            </div>
          </footer>
        </Container>
        </MuiThemeProvider>
      </React.Fragment>
    )
}
