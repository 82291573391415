import React, { useState, FC, useCallback } from 'react';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button } from '@material-ui/core';
import axios from 'axios';

const RecaptchaLink = (params) => {
    const id = params.id;
    const type = params.type;
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');

    const clickHandler = useCallback(async () => {
        if (!executeRecaptcha) {
        return;
        }

        const result = await executeRecaptcha(type);
        setToken(result);
        const response = await axios({
            method: 'post',
            url: `${window._env_.REACT_APP_API_URL}/confirmToken`,
            params: {'token': result, 'id': id, 'type': type},
            // responseType: 'blob' //Force to receive data in a Blob Format
        });
        if (response.status == 200) {
            let fileUrl = `${window._env_.REACT_APP_API_URL}${response.data.file}`
            window.open(fileUrl, "_blank")            
        }
    }, []);


    return (
        <div>
        <Button variant="outlined" color="primary" size="small" onClick={clickHandler}>
            { params.label }
        </Button>
        </div>
    );
};

export default RecaptchaLink;

